
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ACCOUNT_VIEW_KEY = 'AccountView'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AccountViewConfig {
  processingOrderStatus: string
  ongoingShipmentStatus: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const ACCOUNT_VIEW_CONFIG_MAP = {
  processingOrderStatus: 'pendingPayment',
  ongoingShipmentStatus: 'complete',
  redirectFromAccountPage: ''
}
