


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { PossibleOrderStatus } from '../../../../contexts/orders/contracts/orders'
import TipIcon from '../OrdersListItem/partials/TipIcon.vue'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<OrderStatus>({
  name: 'OrderStatus',
  components: { TipIcon }
})
export class OrderStatus extends Vue {
  @Prop({
    type: String,
    required: true
  })
  status!: string

  @Prop({
    type: String,
    required: true
  })
  updatedAt!: string

  @Prop({
    type: Object,
    required: false
  })
  tooltip!: {
    content: string
    placement: string
  }

  /**
   * Prepare status translation.
   * @param status
   */
  public toStatus (status: string): string {
    const isStatusDefined = Object.values(PossibleOrderStatus).includes(status)

    if (!isStatusDefined) {
      return '-'
    }

    return this.$t(`front.orders.OrdersListItem.statuses.${status}`).toString()
  }

  /**
   * Shows tooltip only when current status is 'canceled'
   */
  public get isTooltipApplicable (): boolean {
    if (!this.tooltip || !this.tooltip.content || !this.tooltip.placement) {
      return false
    }

    return (this.status === PossibleOrderStatus.Cancelled) &&
      this.tooltip.content.replace(
        ' ',
        ''
      ).length > 0
  }
}

export default OrderStatus
