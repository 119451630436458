











































import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'

import { CartItemData } from '../../../../contexts'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import { OrdersResponse } from '../../../../contexts/orders/contracts/orders'

import { BaseCartMixin } from '../../../checkout/shared/mixins/base-cart.mixin'
import { IOrdersRepository, OrdersRepositoryType } from '../../../orders/contracts/repository'
import { Loader } from '../../../shared/molecules/Loader'
import {
  OrdersListItemProps,
  translateOrderDataToOrderListItem
} from '../../../orders/molecules/OrdersListItem'

import {
  LastOrderItem,
  LastOrderItemProps, LastOrderItemType,
  PossibleItemAction
} from '../../molecules/LastOrderItem'
import { ProfileWrapper } from '../../molecules/ProfileWrapper'
import { UserMixin } from '../../shared'

import EmptyAccount from './partials/EmptyAccount.vue'
import { UserRefreshMixin } from '../../../shared/mixins/userRefresh.mixin'
import {
  IOrderStatusesService,
  OrderStatusesServiceType
} from '../../../orders/services/order-statuses'
import { OrderStatusesMixin } from '../../../shared/mixins/orderStatuses.mixin'
import { StructureConfigurable } from '../../../../support/mixins'
import { ACCOUNT_VIEW_CONFIG_MAP, ACCOUNT_VIEW_KEY, AccountViewConfig } from './Account.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Account>({
  name: 'Account',
  components: {
    EmptyAccount,
    LastOrderItem,
    Loader,
    ProfileWrapper
  },
  created () {
    this.config = this.getComponentConfig(ACCOUNT_VIEW_KEY, { ...ACCOUNT_VIEW_CONFIG_MAP })
    /**
     * @inheritDoc
     */
    this.getUserOrders()

    if (this.cart) {
      /**
       * @inheritDoc
       */
      this.handleCartData(this.cart.items)
    }
  },
  mounted () {
    if (this.redirectFromAccountPage) {
      this.$router.push({ name: this.redirectFromAccountPage })
    }
  }
})
export class Account extends Mixins(UserMixin, BaseCartMixin, UserRefreshMixin, OrderStatusesMixin, StructureConfigurable) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  @Inject(OrderStatusesServiceType)
  public readonly orderStatusesService!: IOrderStatusesService

  protected config!: AccountViewConfig

  public isLoading: boolean = true

  /** Cart items data */
  public cartData: LastOrderItemProps | null = null
  /** Collection of orders with ongoing shipment */
  public ongoingShipmentOrders: LastOrderItemProps[] = []
  /** Collection of orders with Pending payment status */
  public processingOrders: LastOrderItemProps[] = []

  /**
   * Check whether none of the component data items are present
   */
  public get isEmpty (): boolean {
    return this.ongoingShipmentOrders.length === 0 && this.processingOrders.length === 0 && this.cart?.items.length === 0
  }

  public get userName (): string {
    return this.user ? this.user.firstName : ''
  }

  /**
   * Get latest processing order
   */
  public get processingOrderSingleItem (): LastOrderItemProps {
    return this.processingOrders[0]
  }

  /**
   * Get latest order with ongoing shippment
   */
  public get ongoingShipmentOrderSingleItem (): LastOrderItemProps {
    return this.ongoingShipmentOrders[0]
  }

  public get processingOrderStatus (): string {
    return this.getConfigProperty<string>('processingOrderStatus')
  }

  public get redirectFromAccountPage (): string {
    return this.getConfigProperty<string>('redirectFromAccountPage')
  }

  public get ongoingShipmentStatus (): string {
    return this.getConfigProperty<string>('ongoingShipmentStatus')
  }

  /**
   * Handle user orders data
   * @protected
   */
  protected async getUserOrders () {
    try {
      const ordersResponse: OrdersResponse = await this.ordersRepository.loadOrders(1, 10000)
      const orders = [...ordersResponse.orders]

      if (!orders) {
        return
      }

      const mappedOrders = orders.map((order) => translateOrderDataToOrderListItem(order))
      this.handleOrdersData(mappedOrders)
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Handle orders and compose viewable data
   * @param order
   * @private
   */
  private handleOrdersData (order: Array<OrdersListItemProps>): void {
    const processingOrdersCollection = order.filter((o) => o.item.status === this.processingOrderStatus)
      .sort((a, b) => a.item.createdAt > b.item.createdAt ? -1 : 1)

    this.processingOrders = processingOrdersCollection.map((o): LastOrderItemProps => {
      return {
        headline: {
          prefix: {
            value: this.$t('front.profile.molecules.LastOrderItem.body.pendingPayment.prefix').toString(),
            bold: false
          },
          content: {
            value: o.item.id.toString(),
            bold: true
          },
          suffix: {
            value: this.$t('front.profile.molecules.LastOrderItem.body.pendingPayment.suffix').toString(),
            bold: false
          }
        },
        action: PossibleItemAction.RetryPayment,
        type: LastOrderItemType.Order,
        data: [o.item]
      }
    })

    /**
     * TODO: Verify with backend whether is there any status for ongoing Shipment (before Complete)
     */
    const ongoingShipmentOrdersCollection = order.filter((o) => o.item.status === this.ongoingShipmentStatus)
      .sort((a, b) => a.item.createdAt > b.item.createdAt ? -1 : 1)

    this.ongoingShipmentOrders = ongoingShipmentOrdersCollection.map((o): LastOrderItemProps => {
      return {
        headline: {
          prefix: {
            value: this.$t('front.profile.molecules.LastOrderItem.body.sent.prefix').toString(),
            bold: false
          },
          content: {
            value: o.item.id.toString(),
            bold: true
          },
          suffix: {
            value: this.$t('front.profile.molecules.LastOrderItem.body.sent.suffix').toString(),
            bold: false
          }
        },
        action: PossibleItemAction.ShowDetails,
        type: LastOrderItemType.Order,
        data: [o.item]
      }
    })
  }

  /**
   * Handle cart and compose viewable data
   * @param items
   * @protected
   */
  protected handleCartData (items: CartItemData[]): void {
    this.cartData = {
      headline: {
        prefix: {
          value: String(this.$t('front.profile.molecules.LastOrderItem.body.abandonedCart.prefix')),
          bold: true
        },
        content: {
          value: String(this.$t('front.profile.molecules.LastOrderItem.body.abandonedCart.content')),
          bold: false
        }
      },
      action: PossibleItemAction.GoToCart,
      type: LastOrderItemType.AbandonedCart,
      data: items
    }
  }
}

export default Account
