














































import { Component, Prop, Vue, Watch, Inject as VueInject } from 'vue-property-decorator'
import { AnyObject, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../../support'

import { IToastsService, ToastsServiceType, ToastType } from '../../../../shared/services'
import { Loader } from '../../../../shared/molecules/Loader'
import { SimpleForm, ValidationRules } from '../../../../shared/molecules/Form'
import { UserData } from '../../../../auth/shared'

import { IProfileService, ProfileServiceType } from '../../../contracts'

import { DataEntityType, FormInputType } from './DataFormEntity.config'
import { DataFormEntityPayload } from './DataFormEntity.contracts'
import { PasswordValidatorsMap, UserDataValidatorsMap } from './EditableForm.helpers'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProfileEditableForm>({
  name: 'ProfileEditableForm',
  components: {
    Loader,
    SimpleForm
  },
  created () {
    this.initFormStructure()
  },
  mounted () {
    /**
     * Update errors
     */
    this.eventBus.handle('ui:user-data:errors', (event: EventPayload<Record<string, string[] | undefined>>) => {
      this.errors = event.payload
    })
  },
  beforeDestroy () {
    this.errors = {}
  }
})
export class ProfileEditableForm extends Vue {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(ToastsServiceType, false)
  private readonly toastService?: IToastsService

  @Prop({ type: Object, required: true })
  public readonly data!: DataFormEntityPayload

  @Prop({ type: Object, required: true })
  public readonly user!: UserData

  /** Input entity type */
  public entityType = DataEntityType

  /** Alert error */
  public error: string | null = null
  /** Form errors */
  public errors: Record<string, string[] | undefined> | undefined = {}
  /** Validation error showed when passwords are not the same */
  public repeatErrors: string[] = []

  /** Form payload */
  public formData: AnyObject = {}
  /** Loading state */
  public isLoading: boolean = true
  /** Repeat password payload */
  public passwordRepeat: string = ''
  /** Check if new passwords are the same */
  public samePasswords: boolean = false
  /** Validators map */
  public validatorsMap!: ValidationRules

  public get computedData (): DataFormEntityPayload {
    return this.data
  }

  public get computedError (): string | null {
    return this.error
  }

  /**
   * Consider rendering proper DSL component
   * @param type
   */
  public inputComponent (type: string) {
    if (type === DataEntityType.Password || type === DataEntityType.NewPassword) {
      return FormInputType.Password
    } else if (type === DataEntityType.FirstName || type === DataEntityType.LastName) {
      return FormInputType.Text
    }
  }

  public onSuccess () {
    /** Clear errors if any */
    this.errors = {}

    if (typeof this.toastService === 'undefined') {
      return
    }

    this.toastService.show(this.$t('front._common.saved').toString(), ToastType.Success)

    for (const key of Object.keys(this.formData)) {
      this.formData = {
        ...this.formData,
        [key]: ''
      }
    }
    this.passwordRepeat = ''
  }

  public onFail (errors: Error) {
    logger(errors, 'info')
    this.error = errors.message
  }

  public updateFormData (value: string, dataType: string) {
    this.formData = {
      ...this.formData,
      [dataType]: value
    }
  }

  public async changeUserData () {
    const types = []
    for (const value of Object.values(this.data)) {
      types.push(value.dataType)
    }

    let response

    this.isLoading = true

    try {
      if (types.includes(DataEntityType.Password)) {
        response = await this.profileService.changePassword(this.formData)
      } else if (types.includes(DataEntityType.LastName)) {
        response = await this.profileService.changeUserDetails(this.formData)
      }

      this.$emit('onUpdate')
      return response
    } catch (e) {
      logger((e as Error).message, 'warn')
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Init formData object & validators map
   * @protected
   */
  protected initFormStructure () {
    /** Init formData structure */
    for (const [k, v] of Object.entries(this.user)) {
      for (const item of Object.values(this.data)) {
        if (item.dataType === k) {
          this.formData = {
            ...this.formData,
            [item.dataType]: v
          }
        }
      }
    }

    for (const item of Object.values(this.data)) {
      /** Init validatorsMap */
      if (item.dataType === DataEntityType.FirstName || item.dataType === DataEntityType.LastName) {
        this.validatorsMap = UserDataValidatorsMap
      } else if (item.dataType === DataEntityType.Password) {
        this.validatorsMap = PasswordValidatorsMap
      }

      /** Enable button */
      if (item.dataType === DataEntityType.FirstName || item.dataType === DataEntityType.LastName) {
        this.samePasswords = true
      }
    }

    this.isLoading = false
  }

  /**
   * Watch typing repeated password
   */
  @Watch('passwordRepeat')
  public checkPasswordsSimilarity () {
    for (const v of Object.values(this.computedData)) {
      if (v.dataType === DataEntityType.NewPassword) {
        if (this.formData[DataEntityType.NewPassword] !== this.passwordRepeat) {
          this.repeatErrors = []
          this.repeatErrors.push(String(this.$t('front.profile.views.profile.form.errors.samePasswords')))
          this.samePasswords = false
        } else {
          this.repeatErrors = []
          this.samePasswords = true
        }
      }
    }
  }
}

export default ProfileEditableForm
