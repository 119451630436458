
















































import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { SimpleForm } from '../../../shared/molecules/Form'
import { Loader } from '../../../shared/molecules/Loader'
import { ToastType } from '../../../shared/services'

import { AuthMixin, UserModel } from '../../../auth/shared'

import { UserMixin } from '../../shared'

import { IProfileService, ProfileServiceType } from '../../contracts'

import { ValidatorsMap } from './ChangeUserDetailsForm.helpers'
import { ChangeCredentialsFormData } from './ChangeUserDetailsForm.contracts'
import { EventbusType, IEventbus } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<ChangeUserDetailsForm>({
  name: 'ChangeUserDetailsForm',
  components: { Loader, SimpleForm },
  created () {
    if (!this.isLoggedInUser) {
      return
    }

    this.loadDetails()
  }
})
export class ChangeUserDetailsForm extends Mixins(AuthMixin, FormErrorsMixin, UserMixin) {
  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  /**
   * Validators map.
   */
  public readonly validatorsMap = ValidatorsMap

  /**
   * Forms payload.
   */
  public formData: ChangeCredentialsFormData = {
    firstName: '',
    lastName: '',
    dateOfBirth: ''
  }

  public isLoading: boolean = true

  public get defaultDate (): Date {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 18)
    return date
  }

  public disabledDates (date: Date): boolean {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date > today
  }

  public loadDetails (): void {
    try {
      this.formData.firstName = this.user?.firstName ?? ''
      this.formData.lastName = this.user?.lastName ?? ''
      this.formData.dateOfBirth = this.user?.dateOfBirth ?? ''
    } catch (error) {
      this.setError(error as Error)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Submits the form.
   */
  public async onSubmit (): Promise<UserModel> {
    return this.profileService.changeUserDetails(this.formData)
  }

  /**
   * Handles @success event of form.
   */
  public onSuccess (userModel: UserModel): void {
    this.setUser(userModel)
    this.eventBus.emit('app:user.change_data', userModel)
    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )
  }

  /**
   * Handles @fail event of form.
   */
  public onFail (error: Error): void {
    this.setError(error)
  }

  @Watch('isLoggedInUser')
  public async onWaitingForAuth () {
    this.loadDetails()
  }
}

export default ChangeUserDetailsForm
