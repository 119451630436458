
















import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProfileWrapper } from '../../../molecules/ProfileWrapper'

import { DataEntityType } from './DataFormEntity.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<DataFormEntity>({
  name: 'DataFormEntity',
  components: {
    ProfileWrapper
  }
})
export class DataFormEntity extends Vue {
  @Prop({ type: String, required: true })
  public readonly dataKey!: string

  @Prop({ type: String, required: true })
  public readonly dataType!: string

  @Prop({ type: String, required: false })
  public readonly dataContent?: string

  @Prop({ type: Boolean, required: false, default: true })
  public readonly editable?: boolean

  public get content () {
    if (this.dataType === DataEntityType.Password) {
      return this.$t('front.profile.views.profile.form.password')
    }

    return this.dataContent
  }

  /**
   * Compose EditableForm data
   * @param dataType
   * @param content
   */
  public handleClick (dataType: string, content: string) {
    let types = [dataType]
    const values = content.split(' ')

    /** Enter all DataEntityTypes that should inherit special modificator */
    const specialTypes = [DataEntityType.Password]

    if (dataType === DataEntityType.Name) {
      types = [DataEntityType.FirstName, DataEntityType.LastName]

      this.$emit('onChangeRequest', {
        ...types.map((item, index) => {
          return {
            dataType: item,
            content: values[index],
            label: this.dataType,
            isSpecial: specialTypes.includes(item as DataEntityType) ? '--special' : ''
          }
        })
      })
    } else if (dataType === DataEntityType.Password) {
      types = [DataEntityType.Password, DataEntityType.NewPassword]

      this.$emit('onChangeRequest', {
        ...types.map((item, index) => {
          return {
            dataType: item,
            content: values[index],
            label: this.dataType,
            isSpecial: specialTypes.includes(item as DataEntityType) ? '--special' : ''
          }
        })
      })
    } else {
      this.$emit('onChangeRequest', {
        ...types.map((item) => {
          return {
            dataType: item,
            content: content,
            label: this.dataType,
            isSpecial: specialTypes.includes(item as DataEntityType) ? '--special' : ''
          }
        })
      })
    }
  }
}

export default DataFormEntity
