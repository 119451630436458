







import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<EmptyAccount>({
  name: 'EmptyAccount'
})
export class EmptyAccount extends Vue {
}

export default EmptyAccount
