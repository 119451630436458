







import { Component, Mixins } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'

import {
  PROFILE_VIEW_CONFIG_MAP,
  PROFILE_VIEW_KEY,
  ProfileLayout,
  ProfileViewConfig, profileViewLayoutRegistry
} from './ProfileView.config'
import { UserMixin } from '../../shared'
import { Inject } from '../../../../support'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Profile>({
  name: 'MyData',
  created () {
    this.config = this.getComponentConfig(PROFILE_VIEW_KEY, { ...PROFILE_VIEW_CONFIG_MAP })
  },
  mounted () {
    if (this.$route.params.triggerEvent === 'login') {
      this.triggerLoginObserver()
    }
  }
})
export class Profile extends Mixins(StructureConfigurable, UserMixin) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  protected config!: ProfileViewConfig

  public get component (): VueConstructor | undefined {
    const layout = this.getConfigProperty<string>('layout')

    if (!Object.values(ProfileLayout).includes(layout as ProfileLayout)) {
      return
    }

    return profileViewLayoutRegistry[layout]
  }

  /**
   * Specify which data types should be shown in myData profile view
   */
  public get myDataContentTypes (): Array<string> {
    return this.getConfigProperty<Array<string>>('myDataContentTypes')
  }

  public triggerLoginObserver (): void {
    this.eventBus.emit('app:authorization.login', this.user)
  }
}

export default Profile
