























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import { Loader } from '../../../../shared/molecules/Loader'
import { ToastMixin } from '../../../../shared'
import { ToastType } from '../../../../shared/services'
import { UserData } from '../../../../auth/shared'
import { UserRefreshMixin } from '../../../../shared/mixins/userRefresh.mixin'

import { ProfileWrapper } from '../../../molecules/ProfileWrapper'

import DataFormEntity from '../partials/DataFormEntity.vue'
import { DataEntityType } from '../partials/DataFormEntity.config'
import { DataContent, DataContentEntity } from '../ProfileView.config'
import ProfileEditableForm from '../partials/EditableForm.vue'
import { DataFormEntityPayload } from '../partials/DataFormEntity.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Simple>({
  name: 'Simple',
  components: {
    DataFormEntity,
    Loader,
    ProfileEditableForm,
    ProfileWrapper
  },
  created () {
    /**
     * @inheritDoc
     */
    this.initDataTypes()
  }
})
export class Simple extends Mixins(UserRefreshMixin, ToastMixin) {
  /**
   * Data types that should be visible in view as editable forms e.g. 'e-mail', 'password', ...
   * Data types are specified in application config under [ProfileView.myDataContentTypes] key, default value is empty array
   */
  @Prop({ type: Array, required: true })
  public readonly myDataContentTypes!: Array<string>

  @Prop({ type: Object, required: true })
  public readonly userData!: UserData

  public data: DataContent | null = null

  public selectedData!: DataFormEntityPayload | null

  public showForm: boolean = false

  public get dataContent (): DataContentEntity[] | undefined {
    if (!this.data) {
      return
    }

    return Object.values(Object.values(this.data)).sort((a, b) => a.order > b.order ? -1 : 1)
  }

  public get headline (): string {
    return this.showForm ? this.$t('front.profile.views.profile.myDataEditing').toString()
      : this.$t('front.profile.views.profile.myData').toString()
  }

  public get showFormValue (): boolean {
    return this.showForm
  }

  /**
   * Request data change
   * @param payload
   */
  public onChangeRequest (payload: DataFormEntityPayload) {
    this.selectedData = payload
    this.showForm = true
  }

  public onCancel () {
    this.selectedData = null
    this.showForm = false
  }

  public onUpdate () {
    /**
     * @inheritDoc
     */
    this.getUpdatedUserData()

    this.showToast(String(this.$t('front.profile.views.profile.form.success')), ToastType.Success)

    this.showForm = false
  }

  /**
   * Compose data that should be visible in user profile
   * @protected
   */
  protected initDataTypes (useUserObject: boolean = false): void {
    if (!this.userData) {
      return
    }

    if (!this.user) {
      return
    }

    this.data = {
      email: {
        editable: false,
        key: DataEntityType.Email,
        label: this.$t('front.profile.views.profile.form.heading.email').toString(),
        order: 90,
        value: !useUserObject ? this.userData.email : this.user.email
      },
      name: {
        editable: true,
        key: DataEntityType.Name,
        label: this.$t('front.profile.views.profile.form.heading.name').toString(),
        order: 100,
        value: !useUserObject ? `${this.userData.firstName} ${this.userData.lastName}` : `${this.user.firstName} ${this.user.lastName}`
      },
      password: {
        editable: true,
        key: DataEntityType.Password,
        label: this.$t('front.profile.views.profile.form.heading.password').toString(),
        order: 80,
        value: '********'
      }
    }
  }

  @Watch('user')
  public onUserUpdate () {
    this.initDataTypes(true)
  }
}

export default Simple
