






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { ProfileWrapper } from '../../../molecules/ProfileWrapper'
import { UserData } from '../../../../auth/shared'

import { ChangeAddressForm } from '../../../organisms/ChangeAddressForm'
import { ChangeEmailForm } from '../../../organisms/ChangeEmailForm'
import { ChangePasswordForm } from '../../../organisms/ChangePasswordForm'
import { ChangeUserDetailsForm } from '../../../organisms/ChangeUserDetailsForm'
import {
  changeAddressFormComponentRegistry
} from '../../../organisms/ChangeAddressForm/ChangeAddressForm.config'

import { ProfileViewConfig } from '../ProfileView.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<Default>({
  name: 'Default',
  components: {
    ChangeAddressForm,
    ChangeEmailForm,
    // ChangeNewsletterPreferencesForm,
    ChangePasswordForm,
    ChangeUserDetailsForm,
    ProfileWrapper
  }
})
export class Default extends Vue {
  @Prop({ type: Object, required: true })
  public readonly config!: ProfileViewConfig

  /**
   * Data types that should be visible in view as editable forms e.g. 'e-mail', 'password', ...
   * Data types are specified in application config under [ProfileView.myDataContentTypes] key, default value is empty array
   */
  @Prop({ type: Array, required: true })
  public readonly myDataContentTypes!: Array<string>

  @Prop({ type: Object, required: true })
  public readonly userData!: UserData

  public get changeAddressFormComponent (): VueConstructor {
    return changeAddressFormComponentRegistry[this.config.changeAddressFormComponent]
  }
}

export default Default
